import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import BaseLayout from '../../components/layout/BaseLayout';
import parse from 'html-react-parser'
import { AiFillWindows, AiOutlineCloudDownload } from 'react-icons/ai';
import { w3cwebsocket } from 'websocket'

const DownloadWindows = () => {
    const { t } = useTranslation()

    return (
        <>
            <Helmet>
            </Helmet>
            <BaseLayout>
                <div className="download-page-content content-margin-wrapper">
                    <div className="download-page-left">
                        <p className="download-page-header">
                            {parse(t("download_vpn_windows_header"))}
                        </p>

                        <p className="download-page-subheader">
                            {parse(t("download_vpn_windows_subheader"))}
                        </p>

                        <div className="download-page-actions-container">
                            <div className="download-page-action-button-container" style={{
                                cursor: "default",
                                opacity: .5
                            }}>
                                <p onClick={() => {
                                    // window.open("https://tuatara.space/download/windows")
                                }} className="download-page-actions-button">
                                    <img src={require("../../assets/images/website-download.svg").default} style={{
                                        height: 24,
                                        width: 24,
                                        marginRight: 15
                                    }} />
                                    {parse(t("download_vpn_windows_button_2"))}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="download-page-right">
                        <img src={"https://matreshkavpn.com" + t("download_vpn_windows_image")} className="download-page-right-image" />
                    </div>
                </div>
            </BaseLayout>
        </>
    );
}

export default DownloadWindows;