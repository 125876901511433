import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from '../../utils/authContext';
import request from '../../utils/request';

const Preloader = ({ children }) => {
    const [loadInterval, setLoadInterval] = useState(null);

    const authContext = useContext(AuthContext)

    const loadUser = async () => {
        try {
            const result = await request.get("/api/v1/user/me")

            authContext.setUserData(result.data)

            clearInterval(loadInterval)

            setTimeout(() => {
                document.getElementById('canv').style.opacity = 0;
                document.getElementById('preload-wrap').style.display = "block";
            }, 1000)

            setTimeout(() => {
                document.getElementById('canv').style.display = "none";
            }, 1300)


        } catch (e) {
            if (localStorage.getItem("token"))
                authContext.logout()

            clearInterval(loadInterval)

            setTimeout(() => {
                document.getElementById('canv').style.opacity = 0;
                document.getElementById('preload-wrap').style.display = "block";
            }, 1000)

            setTimeout(() => {
                document.getElementById('canv').style.display = "none";
            }, 1300)

            authContext.setUserData(null)
        }
    }

    useEffect(() => {
        // loadUser()
        const canvas = document.getElementById('canv');
        const ctx = canvas.getContext('2d');

        // set the width and height of the canvas
        const w = canvas.width = document.body.offsetWidth;
        const h = canvas.height = document.body.offsetHeight;

        // draw a black rectangle of width and height same as that of the canvas
        ctx.fillStyle = 'rgb(18, 27, 41)';
        ctx.fillRect(0, 0, w, h);

        const cols = Math.floor(w / 20) + 1;
        const ypos = Array(cols).fill(0);

        function matrix() {
            // Draw a semitransparent black rectangle on top of previous drawing
            ctx.fillStyle = '#0001';
            ctx.fillRect(0, 0, w, h);

            // Set color to green and font to 15pt monospace in the drawing context
            ctx.fillStyle = '#5c03bc';
            ctx.font = '10pt monospace';

            // for each column put a random character at the end
            ypos.forEach((y, ind) => {
                // generate a random character
                const text = Math.random() > 0.5 ? 1 : 0;

                // x coordinate of the column, y coordinate is already given
                const x = ind * 20;
                // render the character at (x, y)
                ctx.fillText(text, x, y);

                // randomly reset the end of the column if it's at least 100px high
                if (y > 100 + Math.random() * 10000) ypos[ind] = 0;
                // otherwise just move the y coordinate for the column 20px down,
                else ypos[ind] = y + 20;
            });
        }

        setLoadInterval(setInterval(matrix, 50));

        setTimeout(() => {
            clearInterval(loadInterval)

            setTimeout(() => {
                document.getElementById('canv').style.opacity = 0;
                document.getElementById('preload-wrap').style.display = "block";
            }, 1000)

            setTimeout(() => {
                document.getElementById('canv').style.display = "none";
            }, 1300)
        }, 1500)

    }, [])

    return (
        <>
            {<canvas width={window.innerWidth} height={window.innerHeight} id="canv" style={{
                position: 'fixed',
                zIndex: 9999,
                transition: ".2s"
            }} />}
            <div id="preload-wrap" style={{
                display: 'none'
            }}>
                {children}
            </div>
        </>
    );
}

export default Preloader;